var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-card",
        { attrs: { header: "Recapiti", "header-tag": "header" } },
        [
          _c(
            "b-card-text",
            [
              _c("b-row", [
                _c(
                  "div",
                  { staticClass: "form-group col-md-3" },
                  [
                    _c("base-input", {
                      attrs: {
                        name: _vm.beECForm.attribute_CELL_value.label,
                        vid: "attribute_CELL_value",
                        label: _vm.beECForm.attribute_CELL_value.label,
                        type: "tel",
                        placeholder: "Inserisci un numero di cellulare",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputVal.attribute_CELL_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "attribute_CELL_value", $$v)
                        },
                        expression: "inputVal.attribute_CELL_value",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-3" },
                  [
                    _c("base-input", {
                      attrs: {
                        name: _vm.beECForm.attribute_MAIL_value.label,
                        vid: "attribute_MAIL_value",
                        label: _vm.beECForm.attribute_MAIL_value.label,
                        placeholder: "Inserisci una mail",
                        rules: { email: true },
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputVal.attribute_MAIL_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "attribute_MAIL_value", $$v)
                        },
                        expression: "inputVal.attribute_MAIL_value",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-3" },
                  [
                    _c("base-input", {
                      attrs: {
                        name: _vm.beECForm.attribute_PHON_value.label,
                        vid: "attribute_PHON_value",
                        label: _vm.beECForm.attribute_PHON_value.label,
                        type: "tel",
                        placeholder: "Inserisci un numero di telefono",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputVal.attribute_PHON_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "attribute_PHON_value", $$v)
                        },
                        expression: "inputVal.attribute_PHON_value",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-3" },
                  [
                    _c("base-input", {
                      attrs: {
                        name: _vm.beECForm.attribute_WEB_value.label,
                        vid: "attribute_WEB_value",
                        label: _vm.beECForm.attribute_WEB_value.label,
                        placeholder: "Inserisci un sito web",
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputVal.attribute_WEB_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "attribute_WEB_value", $$v)
                        },
                        expression: "inputVal.attribute_WEB_value",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group col-md-3" },
                  [
                    _c("base-input", {
                      attrs: {
                        name: _vm.beECForm.attribute_PEC_value.label,
                        vid: "attribute_PEC_value",
                        label: _vm.beECForm.attribute_PEC_value.label,
                        placeholder: "Inserisci una pec",
                        rules: { email: true },
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: _vm.inputVal.attribute_PEC_value,
                        callback: function ($$v) {
                          _vm.$set(_vm.inputVal, "attribute_PEC_value", $$v)
                        },
                        expression: "inputVal.attribute_PEC_value",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }