var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "lg",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { ok: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "people" } }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v("Modifica una " + _vm._s(_vm.title) + " "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel()
                      _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c("validation-observer", {
        ref: "observer1",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function ({ handleSubmit, invalid }) {
              return [
                _c(
                  "b-form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [
                    _c(
                      "b-card",
                      {
                        attrs: {
                          header: "Dati Generali",
                          "header-tag": "header",
                        },
                      },
                      [
                        _c(
                          "b-card-text",
                          [
                            _c("b-row", [
                              _c(
                                "div",
                                { staticClass: "form-group col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: _vm.beFormEdit[_vm.rep]
                                        .attribute_FULN_value.label,
                                      vid: "attribute_FULN_value",
                                      label:
                                        _vm.beFormEdit[_vm.rep]
                                          .attribute_FULN_value.label,
                                      placeholder: "Inserisci un nome",
                                      rules: { required: true },
                                      disabled: true,
                                    },
                                    model: {
                                      value:
                                        _vm.inputVal[_vm.rep]
                                          .attribute_FULN_value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal[_vm.rep],
                                          "attribute_FULN_value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inputVal[rep].attribute_FULN_value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "form-group col-md-3" },
                                [
                                  _c("base-input", {
                                    attrs: {
                                      name: _vm.beFormEdit[_vm.rep]
                                        .attribute_NINO_value.label,
                                      vid: "attribute_NINO_value",
                                      label:
                                        _vm.beFormEdit[_vm.rep]
                                          .attribute_NINO_value.label,
                                      textcase: "upper",
                                      placeholder:
                                        "Inserisci un codice fiscale",
                                      rules: _vm.getRules(
                                        "attribute_NINO_value",
                                        "claim_party"
                                      ),
                                      disabled: _vm.isImportedClaim,
                                    },
                                    model: {
                                      value:
                                        _vm.inputVal[_vm.rep]
                                          .attribute_NINO_value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.inputVal[_vm.rep],
                                          "attribute_NINO_value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "inputVal[rep].attribute_NINO_value",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.status_claimparty === 3
                                ? _c(
                                    "div",
                                    { staticClass: "form-group col-md-3" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          name: _vm.beFormEdit[_vm.rep]
                                            .attribute_CREG_value.label,
                                          vid: "attribute_CREG_value",
                                          label:
                                            _vm.beFormEdit[_vm.rep]
                                              .attribute_CREG_value.label,
                                          placeholder:
                                            "Inserisci una ragione sociale",
                                          rules: _vm.getRules(
                                            "attribute_CREG_value",
                                            "claim_party"
                                          ),
                                        },
                                        model: {
                                          value:
                                            _vm.inputVal[_vm.rep]
                                              .attribute_CREG_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputVal[_vm.rep],
                                              "attribute_CREG_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputVal[rep].attribute_CREG_value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.status_claimparty === 1
                                ? _c(
                                    "div",
                                    { staticClass: "form-group col-md-3" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          name: _vm.beFormEdit[_vm.rep]
                                            .attribute_CPINS_value.label,
                                          vid: "attribute_CPINS_value",
                                          label:
                                            _vm.beFormEdit[_vm.rep]
                                              .attribute_CPINS_value.label,
                                          placeholder:
                                            "Inserisci una compagnia",
                                          disabled: _vm.isImportedClaim,
                                        },
                                        model: {
                                          value:
                                            _vm.inputVal[_vm.rep]
                                              .attribute_CPINS_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputVal[_vm.rep],
                                              "attribute_CPINS_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputVal[rep].attribute_CPINS_value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.status_claimparty === 1
                                ? _c(
                                    "div",
                                    { staticClass: "form-group col-md-3" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          name: _vm.beFormEdit[_vm.rep]
                                            .attribute_CPRGP_value.label,
                                          vid: "attribute_CPRGP_value",
                                          label:
                                            _vm.beFormEdit[_vm.rep]
                                              .attribute_CPRGP_value.label,
                                          placeholder: "Inserisci una targa",
                                        },
                                        model: {
                                          value:
                                            _vm.inputVal[_vm.rep]
                                              .attribute_CPRGP_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputVal[_vm.rep],
                                              "attribute_CPRGP_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputVal[rep].attribute_CPRGP_value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.status_claimparty === 1
                                ? _c(
                                    "div",
                                    { staticClass: "form-group col-md-3" },
                                    [
                                      _c("base-input", {
                                        attrs: {
                                          name: _vm.beFormEdit[_vm.rep]
                                            .attribute_CPVEH_value.label,
                                          vid: "attribute_CPVEH_value",
                                          label:
                                            _vm.beFormEdit[_vm.rep]
                                              .attribute_CPVEH_value.label,
                                          placeholder: "Inserisci un veicolo",
                                        },
                                        model: {
                                          value:
                                            _vm.inputVal[_vm.rep]
                                              .attribute_CPVEH_value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputVal[_vm.rep],
                                              "attribute_CPVEH_value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "inputVal[rep].attribute_CPVEH_value",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("address-book-form", {
                      attrs: {
                        beECForm: _vm.beFormEdit[_vm.addrBook],
                        repository: _vm.addrBook,
                        disabled: _vm.isImportedClaim,
                      },
                      model: {
                        value: _vm.address_book_form,
                        callback: function ($$v) {
                          _vm.address_book_form = $$v
                        },
                        expression: "address_book_form",
                      },
                    }),
                    _vm._v(" "),
                    _c("correspondence-form", {
                      attrs: {
                        beECForm: _vm.beFormEdit[_vm.cor],
                        repository: _vm.cor,
                        disabled: _vm.isImportedClaim,
                      },
                      model: {
                        value: _vm.correspondence_form,
                        callback: function ($$v) {
                          _vm.correspondence_form = $$v
                        },
                        expression: "correspondence_form",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "mt-2 float-right",
                        attrs: {
                          type: "button",
                          disabled: invalid,
                          variant: "outline-lisaweb",
                          size: "sm",
                        },
                        on: {
                          click: function ($event) {
                            return handleSubmit(_vm.update)
                          },
                        },
                      },
                      [_vm._v("\n        Aggiorna\n      ")]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }