var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "lg",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", { attrs: { icon: "people" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Associa " + _vm._s(_vm.title))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return cancel()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "b-button-group",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "collapse-2",
              expression: "'collapse-2'",
            },
          ],
          staticClass: "my-2 filter-button-group",
          attrs: { title: _vm.getDefaultFilterMessage() },
        },
        [
          _c(
            "span",
            { staticClass: "when-open" },
            [_c("b-icon", { attrs: { icon: "funnel", "font-scale": "1.5" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "when-closed" },
            [_c("b-icon", { attrs: { icon: "funnel", "font-scale": "1.5" } })],
            1
          ),
          _vm._v("\n    Filtra\n  "),
        ]
      ),
      _vm._v(" "),
      _c("b-collapse", { attrs: { visible: "", id: "collapse-2" } }, [
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.onSearch(_vm.filterName)
                  },
                },
              },
              [
                _c(
                  "b-card",
                  { staticClass: "filter" },
                  [
                    _c("b-row", [
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("base-input", {
                            attrs: {
                              name: "lastName",
                              type: "text",
                              label: "Nome Completo",
                              placeholder: "Digita Nome Completo",
                            },
                            model: {
                              value: _vm.filter.byAttribute.FULN,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter.byAttribute, "FULN", $$v)
                              },
                              expression: "filter.byAttribute.FULN",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("base-input", {
                            attrs: {
                              name: "lastName",
                              type: "text",
                              label: "Codice Fiscale",
                              placeholder: "Digita il codice fiscale",
                            },
                            model: {
                              value: _vm.filter.byAttribute.NINO,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter.byAttribute, "NINO", $$v)
                              },
                              expression: "filter.byAttribute.NINO",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-md-3" },
                        [
                          _c("base-input", {
                            attrs: {
                              name: "businessName",
                              type: "text",
                              label: "Partita IVA",
                              placeholder: "Digita la Partita IVA",
                            },
                            model: {
                              value: _vm.filter.byAttribute.CREG,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter.byAttribute, "CREG", $$v)
                              },
                              expression: "filter.byAttribute.CREG",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-4 align-self-end" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                type: "submit",
                                variant: "lisaweb",
                                size: "sm",
                              },
                            },
                            [_vm._v("Cerca")]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn-reset",
                              attrs: {
                                type: "button",
                                variant: "lisaweb",
                                size: "sm",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onClearFilter(_vm.filterName)
                                },
                              },
                            },
                            [_vm._v("Reset")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("claim-parties", {
        ref: _vm.tableRef,
        attrs: {
          hasChecks: "",
          noActions: "",
          noCustom: "",
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          noInnerWidth: "",
        },
      }),
      _vm._v(" "),
      _c(
        "b-button",
        {
          staticClass: "mt-2 float-right",
          attrs: { type: "button", variant: "outline-lisaweb", size: "sm" },
          on: {
            click: function ($event) {
              return _vm.saveEvent()
            },
          },
        },
        [_vm._v("\n    Salva\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }